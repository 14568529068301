import React from "react"

// Setup our context with initial state
const ModalContext = React.createContext({
  // State
  activeModal: null,
  // Functions
  openModal: () => {},
  closeModal: () => {},
})

// Build our provider (wrapper)
export class ModalProvider extends React.Component {
  state = {
    activeModal: null,
  }

  closeModal = () => {
    this.setState({
      activeModal: null,
    })
  }

  openModal = (modal) => {
    const { activeModal } = this.props
    if (activeModal) return

    this.setState({
      activeModal: React.cloneElement(modal, {
        onClose: modal.props.onClose || this.closeModal,
      }),
    })
  }

  render() {
    const { children } = this.props
    const { activeModal } = this.state

    return (
      <ModalContext.Provider
        value={{
          // State
          activeModal,
          // Functions
          openModal: this.openModal,
          closeModal: this.closeModal,
        }}
      >
        {children}
      </ModalContext.Provider>
    )
  }
}

// Build our conductor (wrapper)
export const ModalConductor = () => {
  const [currentDialog, setCurrentDialog] = React.useState(null)
  const { activeModal } = React.useContext(ModalContext)

  React.useEffect(() => {
    if (currentDialog && activeModal) return

    if (!activeModal && currentDialog) {
      return closeWithDelay()
    }

    setCurrentDialog(activeModal)
  }, [activeModal, currentDialog])

  const closeWithDelay = () => {
    setTimeout(() => {
      setCurrentDialog(null)
    }, 350)
  }

  return currentDialog ? (
    <>
      {React.cloneElement(currentDialog, {
        isVisible: !!activeModal,
      })}
    </>
  ) : null
}

// Make a simple export for our context
export const withModalContext = (Component) => {
  return function WrapperComponent(props) {
    return <ModalContext.Consumer>{(state) => <Component {...props} modalContext={state} />}</ModalContext.Consumer>
  }
}

export default ModalContext
