// Utilities
import baseAxios from "./base"

const RecipeApi = {
  all: () => {
    return baseAxios.get("/recipes")
  },

  fetch: (id) => {
    return baseAxios.get(`/recipes/${id}`)
  },

  search: (query) => {
    return baseAxios.post(`/recipes/search`, { query })
  },
}

export default RecipeApi
