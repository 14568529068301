import React from "react"
import loadable from "@loadable/component"
// Sentry
import * as Sentry from "@sentry/browser"
// Intl
import { IntlProvider } from "react-intl"
import messagesEn from "translations/en.json"
// Router
import { Redirect, Switch } from "react-router-dom"
import AppRoute from "utils/routing/AppRoute"

import { ModalConductor, ModalProvider } from "contexts/ModalContext"
import { SnackbarConductor, SnackbarContextProvider } from "contexts/SnackbarContext"
import { BreadcrumbContextProvider } from "contexts/BreadcrumbContext"
import { RecipeContextProvider } from "contexts/RecipeContext"
import "../public/assets/styles/global.css"
import { WidgetContextProvider } from "./contexts/WidgetContext"
import ScrollToTop from "./utils/ScrollToTop"
import ImageModal from "./components/elements/ImageModal"
import { ImageModalProvider } from "./contexts/ImageModalContext"

// Initialize sentry
if (process.env.RAZZLE_APP_SENTRY_KEY) {
  Sentry.init({ dsn: process.env.RAZZLE_APP_SENTRY_KEY })
}

// Pages
const HomePage = loadable(() => import("pages/HomePage"))
const AboutPage = loadable(() => import("pages/AboutPage"))
const ContactPage = loadable(() => import("pages/ContactPage"))
const InstagramPage = loadable(() => import("pages/InstagramPage"))
const RecipesPage = loadable(() => import("pages/recipes/RecipesPage"))
const RecipePage = loadable(() => import("pages/recipes/RecipePage"))
const IngredientsPage = loadable(() => import("pages/ingredients/IngredientsPage"))
const TermsPage = loadable(() => import("pages/TermsPage"))
const PrivacyPage = loadable(() => import("pages/PrivacyPage"))

const messages = {
  en: messagesEn,
}

// language without region code
const language = typeof window !== "undefined" ? navigator.language.split(/[-_]/)[0] : null

export const routes = [
  {
    id: "about",
    name: "About",
    path: "/about",
    component: AboutPage,
    exact: true,
  },
  {
    id: "recipes",
    name: "Recipes",
    path: "/recipes",
    component: RecipesPage,
    exact: true,
  },
  {
    id: "recipe",
    name: "Recipe",
    path: "/recipes/:recipeId",
    component: RecipePage,
    exact: true,
  },
  {
    id: "ingredients",
    name: "Ingredients",
    path: "/ingredients",
    component: IngredientsPage,
    exact: true,
  },
  {
    id: "contact",
    name: "Contact",
    path: "/contact",
    component: ContactPage,
    exact: true,
  },
  {
    id: "instagram",
    name: "Instagram",
    path: "/instagram",
    component: InstagramPage,
    exact: true,
  },
  {
    id: "terms",
    name: "Terms & Conditions",
    path: "/terms",
    component: TermsPage,
    exact: true,
  },
  ,
  {
    id: "privacy",
    name: "Privacy Policy",
    path: "/privacy",
    component: PrivacyPage,
    exact: true,
  },
  ,
  {
    id: "home",
    name: "Home",
    path: "/",
    component: HomePage,
    exact: true,
  },
]

const InternalApp = () => {
  // Store our loading state (initializing)
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (isLoaded || isLoading) return
    setIsLoading(true)

    // Do something
    setIsLoaded(true)
    setIsLoading(false)
  }, [isLoaded, isLoading])

  return (
    <>
      <ModalConductor />
      <SnackbarConductor />
      <ScrollToTop />
      <ImageModal />
      {isLoaded ? (
        <Switch>
          {routes.map(({ id, ...rest }) => (
            <AppRoute key={id} {...rest} />
          ))}
        </Switch>
      ) : null}
    </>
  )
}

function App() {
  return (
    <IntlProvider locale={language || "en"} messages={messages[language]}>
      <WidgetContextProvider>
        <RecipeContextProvider>
          <BreadcrumbContextProvider>
            <SnackbarContextProvider>
              <ModalProvider>
                <ImageModalProvider>
                  <InternalApp />
                </ImageModalProvider>
              </ModalProvider>
            </SnackbarContextProvider>
          </BreadcrumbContextProvider>
        </RecipeContextProvider>
      </WidgetContextProvider>
    </IntlProvider>
  )
}

export default App
