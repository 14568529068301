import React from "react"
import Snackbar from "components/elements/Snackbar"

// Setup our context with initial state
const SnackbarContext = React.createContext({
  activeSnackbar: null,
  // Functions
  showSnackbar: () => {},
})

// Build our provider (wrapper)
export const SnackbarContextProvider = ({ children }) => {
  const [activeSnackbar, setActiveSnackbar] = React.useState(null)

  const showSnackbar = React.useCallback((props) => {
    if (activeSnackbar) return
    setActiveSnackbar(<Snackbar {...props} />)
    setTimeout(() => setActiveSnackbar(null), 2500)
  }, [])

  return (
    <SnackbarContext.Provider
      value={{
        activeSnackbar,
        // Functions
        showSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  )
}

// Build our conductor (wrapper)
export const SnackbarConductor = () => {
  const [currentSnackbar, setCurrentSnackbar] = React.useState(null)
  const { activeSnackbar } = React.useContext(SnackbarContext)

  React.useEffect(() => {
    if (currentSnackbar && activeSnackbar) return

    if (!activeSnackbar && currentSnackbar) {
      return closeWithDelay()
    }
    setCurrentSnackbar(activeSnackbar)
  }, [activeSnackbar, currentSnackbar])

  const closeWithDelay = () => {
    setTimeout(() => {
      setCurrentSnackbar(null)
    }, 350)
  }

  return currentSnackbar ? (
    <>
      {React.cloneElement(currentSnackbar, {
        open: !!activeSnackbar,
      })}
    </>
  ) : null
}

// Make a simple export for our context for class-based components
export const withSnackbarContext = (Component) => {
  return function WrapperComponent(props) {
    return (
      <SnackbarContext.Consumer>{(state) => <Component {...props} snackbarContext={state} />}</SnackbarContext.Consumer>
    )
  }
}

export default SnackbarContext
