import React from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (typeof window === "undefined") return

    const { location } = this.props
    const { location: prevLocation } = prevProps

    if (location.pathname !== prevLocation.pathname && !location.hash) {
      window.scrollTo({
        top: 0,
        left: 0,
      })
    }
  }

  render() {
    return null
  }
}

export default withRouter(ScrollToTop);
