import React from "react"
import { Route } from "react-router-dom"

import { BreadcrumbContext } from "contexts/BreadcrumbContext"
import { routes } from "App"

const Page = ({ crumbs, component: Component, ...props }) => {
  const { update: updateBreadcrumbs } = React.useContext(BreadcrumbContext)

  React.useEffect(() => {
    // Create an array of current crumbs
    const crumbs = routes
      // Get all routes that contain the current one.
      .filter(({ path }) => props.match.path.includes(path))
      // Swap out any dynamic routes with their param values.
      // E.g. "/pizza/:pizzaId" will become "/pizza/1"
      .map(({ path, ...rest }) => ({
        path: Object.keys(props.match.params).length
          ? Object.keys(props.match.params).reduce(
              (path, param) => path.replace(`:${param}`, props.match.params[param]),
              path
            )
          : path,
        ...rest,
      }))

    // Store these in our context so our breadcrumbs component knows about them!
    updateBreadcrumbs(crumbs)
  }, [])

  return <Component {...props} />
}

const AppRoute = ({ component: Component, allow, path, ...rest }) => {
  return <Route {...rest} path={path} render={(props) => <Page component={Component} {...props} />} />
}

export default AppRoute
