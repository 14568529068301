import React from "react"
import ImageModalContext from "../../contexts/ImageModalContext"

const ImageModal = () => {
  const { activeImage, setActiveImage } = React.useContext(ImageModalContext)
  const [currentImage, setCurrentImage] = React.useState(null)
  const [visible, setVisible] = React.useState(false)

  React.useEffect(() => {
    // Add the image
    if (activeImage && !currentImage) {
      setCurrentImage(activeImage)
      setTimeout(() => setVisible(true), 25)
    }

    // Remove the image
    if (!activeImage && currentImage) {
      setVisible(false)
      setTimeout(() => setCurrentImage(null), 250)
    }
  }, [activeImage, currentImage])

  const handleClose = () => {
    setActiveImage(null)
  }

  return currentImage ? (
    <div className={"z-50 h-full w-full fixed inset-0"}>
      <div
        className={`fixed z-50 bg-white h-full w-full inset-0 bg-opacity-80 transform transition-all duration-200 ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      ></div>
      <div className={"flex items-center justify-center fixed z-50 h-full w-full inset-0 p-8"} onClick={handleClose}>
        <div className={"max-h-screen max-w-screen w-full h-full flex items-center justify-center"}>
          <img
            key={"image-modal-image"}
            src={currentImage.url}
            alt={currentImage.alt}
            className={`max-h-full max-w-full object-cover shadow-lg transform transition-all duration-200 ${
              visible ? "scale-100 opacity-100" : "scale-50 opacity-0"
            }`}
          />
        </div>
        <div
          className={
            "fixed z-50 top-0 right-0 h-12 w-12 pt-1 text-5xl flex items-center justify-center text-cool-gray-400 cursor-pointer hover:text-cool-gray-500"
          }
          onClick={handleClose}
        >
          ×
        </div>
      </div>
    </div>
  ) : null
}

export default ImageModal
