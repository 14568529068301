import React from "react"
import isEqual from "lodash/isEqual"

export const BreadcrumbContext = React.createContext({
  breadcrumbs: [],
  setBreadCrumbs: () => {},
})

export const BreadcrumbContextProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = React.useState([])

  const update = React.useCallback(
    (crumbs) => {
      if (isEqual(crumbs, breadcrumbs)) return
      setBreadcrumbs(crumbs)
    },
    [breadcrumbs]
  )

  return <BreadcrumbContext.Provider value={{ breadcrumbs, update }}>{children}</BreadcrumbContext.Provider>
}
