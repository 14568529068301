// Utilities
import axios from "axios"

const BaseAxios = axios.create({
  baseURL: `/api/${process.env.RAZZLE_APP_API_VERSION}`,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    timeout: 5000,
    responseType: "json",
  },
})

export const formDataFromParams = (params) => {
  let formData = new FormData()

  Object.keys(params).forEach((key) => {
    formData.append(key, params[key])
  })

  return formData
}

export default BaseAxios
