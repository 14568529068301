// Utilities
import baseAxios from "./base"

const PageApi = {
  all: () => {
    return baseAxios.get("/pages")
  },
}

export default PageApi
