import React from "react"
import isEqual from "lodash/isEqual"
import RecipeApi from "../utils/api/recipe"

export const RecipeContext = React.createContext({
  all: [],
  isLoading: false,
  fetchAll: () => {},
})

export const RecipeContextProvider = ({ children }) => {
  const [all, setAll] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  const fetchAll = React.useCallback(
    () => {
      if (isLoading) return
      if (all.length) return all

      const loadAll = async () => {
        try {
          const recipesResponse = await RecipeApi.all()
          setAll(recipesResponse.data.data)
        } catch (error) {
          console.log({ error })
        } finally {
          setIsLoading(false)
        }
      }

      loadAll()
    },
    [all, isLoading]
  )

  return <RecipeContext.Provider value={{ all, isLoading, fetchAll }}>{children}</RecipeContext.Provider>
}
