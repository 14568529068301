import React from "react"
import PageApi from "../utils/api/page";

export const WidgetContext = React.createContext({
  all: [],
  isLoading: false,
  fetchAll: () => {},
})

export const WidgetContextProvider = ({ children }) => {
  const [all, setAll] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  const fetchAll = React.useCallback(
    () => {
      if (isLoading) return
      if (all.length) return all

      const loadAll = async () => {
        try {
          const response = await PageApi.all()
          setAll(response.data.data)
        } catch (error) {
          console.log({ error })
        } finally {
          setIsLoading(false)
        }
      }

      loadAll()
    },
    [all, isLoading]
  )

  return <WidgetContext.Provider value={{ all, isLoading, fetchAll }}>{children}</WidgetContext.Provider>
}
