import React from "react"

// Setup our context with initial state
const ImageModalContext = React.createContext({
  // State
  activeImage: null,
  setActiveImage: () => {},
})

// Build our provider (wrapper)
export class ImageModalProvider extends React.Component {
  state = {
    activeImage: null,
  }

  setActiveImage = (activeImage) => {
    this.setState({ activeImage })
  }

  render() {
    const { children } = this.props
    const { activeImage } = this.state

    return (
      <ImageModalContext.Provider
        value={{
          // State
          activeImage,
          setActiveImage: this.setActiveImage,
        }}
      >
        {children}
      </ImageModalContext.Provider>
    )
  }
}

export default ImageModalContext
